define("innowaze-ember2/routes/challenges", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "innowaze-ember2/mixins/routes/requestAccess", "innowaze-ember2/mixins/routes/socialConnect"], function (_exports, _objectSpread2, _requestAccess, _socialConnect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_requestAccess.default, _socialConnect.default, {
    locale: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        challenges: this.store.findAll("challenge", {
          reload: true
        }).then(function (challenges) {
          return challenges.filterBy("closed", false);
        })
      });
    },
    redirect: function redirect(model, transition) {
      // special case for SSO SAML deeplinks
      var samlDeeplinkCookie = Cookies.get("saml_deeplink");

      if (samlDeeplinkCookie) {
        Cookies.remove("saml_deeplink");

        if (this.get("session.configuration.activePrivateSSO").includes("saml")) {
          this.router.replaceWith(samlDeeplinkCookie);
        }
      }

      if (!this.get("session.configuration.dict.one_challenge")) {
        return true;
      }

      if (model.challenges.length === 1) {
        var onlyChallenge = model.challenges.get("firstObject"); // The one_challenge option is on and there is only one active challenge for the current user
        // We redirect to this only active challenge under those conditions:
        // 1. -> this challenge does not require validation
        // 2. -> this challenge requires validation and user is granted yet i.e. is not under validation review
        // 3. -> there is indeed a current_user_id defined in the payload of this challenge

        if ((!onlyChallenge.requires_validation || onlyChallenge.requires_validation && onlyChallenge.granted) && onlyChallenge.current_user_id) {
          this.router.transitionTo("challenges.show", onlyChallenge.id);
        }
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      this.set("session.availableChallenges", model.challenges);

      if (this.get("session.configuration.dict.events")) {
        this.store.adapterFor("event").findGroups().then(function (groups) {
          _this.session.set("availableEventGroups", groups.map(function (g) {
            return Ember.Object.create(g);
          }));

          _this.session.get("availableEventGroups").setEach("selected", true);

          _this.store.findAll("event").then(function (events) {
            controller.set("events", events);
          });
        });
      }

      if (this.get("session.configuration.dict.department_headlines")) {
        this.store.query("news", {
          newsable_type: ["Department"],
          order: "published_at",
          last_three: true,
          size: 4
        }).then(function (news) {
          controller.set("lastDepartmentHeadlines", news);
        });
      }

      if (this.get("session.configuration.dict.last_departments")) {
        this.store.query("department", {
          public: true,
          published: true,
          order: "created_at",
          size: 10
        }).then(function (departments) {
          controller.set("displayLastDepartments", departments);
        });
      }

      if (this.get("session.configuration.dict.sponsors")) {
        this.store.query("department", {
          public: true,
          sponsor: true,
          published: true,
          size: 20
        }).then(function (departments) {
          controller.set("displaySponsors", departments);
        });
      }

      if (this.get("session.configuration.dict.last_news")) {
        this.store.query("news", {
          newsable_type: ["Instance"],
          order: "published_at",
          last_three: true,
          displayed: true
        }).then(function (news) {
          controller.set("news", news);
        });
      }

      if (this.get("session.configuration.dict.universes")) {
        controller.set("disableUniverseBtn", false);
        this.store.query("universe", {
          published: true
        }).then(function (universes) {
          _this.session.set("availableUniverses", universes);

          controller.set("universes", [{
            id: "all",
            name: _this.locale.t("universes.all_universes")
          }, {
            id: "instance",
            name: _this.locale.t("universes.instance_challenges")
          }]);
          controller.universes.pushObjects(_this.session.get("sortedUniverses").map(function (universe) {
            return {
              id: universe.id,
              name: universe.get("name")
            };
          }));

          if (!controller.universe) {
            if (_this.get("session.currentUser.default_universe_id")) {
              controller.set("universe", _this.get("session.currentUser.default_universe_id"));
            } else {
              controller.set("universe", "all");
            }
          }

          controller.set("firstLoad", false);
        });
      }

      this.store.findAll("department").then(function (departments) {
        controller.set("departments", [{
          id: "all",
          name: _this.locale.t("departments.all_departments")
        }]);
        controller.departments.pushObjects(departments.filterBy("customer", true));
      });
      this.store.adapterFor("challenge").findGroups().then(function (groups) {
        controller.set("availableGroups", [{
          id: "all",
          name: _this.locale.t("challenges.index.all_groups")
        }]);
        controller.availableGroups.pushObjects(groups.map(function (group) {
          return (0, _objectSpread2.default)({}, group, {
            id: group.id.toString()
          });
        }));
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("department", null);
        controller.set("universe", null);
        controller.set("group", null);
        controller.set("nameFilter", "");
      }
    },
    actions: {
      willTransition: function willTransition() {
        if (this.get("controller.intervalId")) {
          clearInterval(this.get("controller.intervalId"));
          this.set("controller.intervalId", null);
        }

        return true;
      },
      scrollTo: function scrollTo(id) {
        this._scrollTo(id);
      },
      toggleDefaultUniverse: function toggleDefaultUniverse(id) {
        var _this2 = this;

        this.set("controller.disableUniverseBtn", true);
        this.set("session.currentUser.default_universe_id", id);
        this.get("session.currentUser").save().finally(function () {
          return _this2.set("controller.disableUniverseBtn", false);
        });
      },
      selectNews: function selectNews(news) {
        if (this.get("controller.intervalId")) {
          clearInterval(this.get("controller.intervalId"));
          this.set("controller.intervalId", null);
        }

        this.get("controller.lastArticles").setEach("active", false);
        news.set("active", true);
      }
    },
    _scrollTo: function _scrollTo(id) {
      $("html, body").animate({
        scrollTop: $("#" + id).offset().top - 50
      });
    }
  });

  _exports.default = _default;
});